import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import PhotoGallery from "../../components/gallery/photo-gallery-page";

const GalleryPage = () => {
    return (
        <Layout pageTitle="Jci India Foundation | Photo Gallery">
            <NavOne />
            <PageHeader title="Photo Gallery" />
            <PhotoGallery />
            <Footer />
        </Layout>
    );
};

export default GalleryPage;
